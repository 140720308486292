import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {
        return (
            <>
                { this.state.navigate && <Navigate to="/category" replace={true} /> }


                <div className='intro' >
                    <div className="head">
                        <img src={this.settings.logo} alt="" />
                        <h3>{this.settings.name}</h3>
                        <p>
                            Since 1995 <br/>
                            By Amin Younis
                        </p>
                    </div>
                    <form onSubmit={this.handleSubmit} >
                        <button type='submit' onClick={() => this.handleChange('krd')} > کوردی </button>
                        <button type='submit' onClick={() => this.handleChange('ar')}> عربی </button>
                        <button type='submit' onClick={() => this.handleChange('en')}> English </button>
                    </form>

                    <div className="social">

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook"></i></a>
                        }

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                    </div>

                    <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <i class="fa-solid fa-message"></i> Feedback 
                        </Link>
                    </div>

                </div>
            </>
        );
    }
}

export default Intro;